import React, {Component} from 'react'
import UploadedFileItem from './UploadFileItem';

export default class SelectedFilesList extends Component {

  filesList(){
      return this.props.selectedFiles.map((file, idx) => {
          return (
            <UploadedFileItem removeSelectedFile={this.props.removeSelectedFile} file={file} />
          )
      })
  }

  render() {
    return (
      <ol>
        {this.filesList()}          
      </ol>
    )
  }
}
