import React, { Component } from 'react'
import { Button } from '@blueprintjs/core';

export default class UploadedFileItem extends Component {

    constructor(props){
        super(props);
        this.removeSelectedFile = this.removeSelectedFile.bind(this);
    }

    removeSelectedFile(){
        this.props.removeSelectedFile(this.props.file);
    }

    render() {
        return (
            <li style={{width: '100%', float: 'right'}}>
                {this.props.file.name}
                <Button icon={'delete'}
                     className={'delete-button'} 
                     onClick={this.removeSelectedFile} />
            </li>
      )
  }
}
