export const AUTH_LOGIN_USER_REQUEST = 'AUTH_LOGIN_USER_REQUEST';
export const AUTH_LOGIN_USER_FAILURE = 'AUTH_LOGIN_USER_FAILURE';
export const AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS';
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';

export const DOCUMENTS_FETCHED = 'DOCUMENTS_FETCHED';
export const UPDATE_FILES_LIST =  'UPDATE_FILES_LIST';
export const MARK_DOCUMENT_TO_SEND = 'MARK_DOCUMENT_TO_SEND';

export const ALL_DOCS = "allDocuments";
export const VALIDATED = 'verified';
export const PROCESSING = 'processing';
export const PENDING = 'pending';
export const SENT = "sent";
export const MARKED_TO_SEND = 'markedToSend';



export const DOC_STATUS = {
    PENDING,
    VALIDATED,
    PROCESSING,
    SENT,
    MARKED_TO_SEND
};


export const ENTITY_KEY_TO_TITLE = {
    'meetingDate': 'Meeting Date',
    'meetingAddress': 'Meeting Address',
    'meetingAgenda': 'Meeting Agenda',
    'meetingDeadline': 'Deadline Date',
    'companyName': 'Company Name',
    'meetingType': 'Meeting Type',
    'companyDeadline': 'Company Deadline',
    'isin': "ISIN",
    "recordDate": "Record Date",
    'documentType': 'Document Type',
    "ourDeadline": "Our Deadline"
};


export const OPTIONS = [
    { key: ALL_DOCS, display: "Show All" },
    { key: VALIDATED, display: 'Validated' },
    { key: PROCESSING, display: 'Processing' },
    { key: PENDING, display: 'Pending' },
    { key: SENT, display: "Sent Items" },
    { key: MARKED_TO_SEND, display: "Marked Items" },
];


export const KEY_TO_CLASS = {
    'W': 'pending',
    'P': 'processing',
    'V': 'verified',
    'M': 'marked_to_send',
    'S': 'sent'
};