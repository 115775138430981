import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Icon} from '@blueprintjs/core';

import './style.css';

import {ALL_DOCS, OPTIONS} from "../../constants/index";
import {fetchDocumentsFromServer} from "../../actions/docs";
import UploadNewFile from './UploadNewFile';
import {DOCS_URL} from '../../utils/config';
import {checkHttpStatus, getCookie, handleCommonErrors, parseJSON} from '../../utils';
import {exportToCSV} from '../../utils/csvUtils';
import DocumentsTable from "./DocumentsTable";


class HomeView extends React.Component {
    static propTypes = {
        statusText: PropTypes.string,
        userName: PropTypes.string,
        dispatch: PropTypes.func.isRequired,
    };

    static defaultProps = {
        statusText: '',
        userName: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            showUploadDialog: false,
            filter: null,
            news: [],
            totalDocs: 0,
            selectedPage: 1,
        };
        this.uploadNewFileClicked = this.uploadNewFileClicked.bind(this);
        this.closeUploadDialog = this.closeUploadDialog.bind(this);
        this.onFilterOptionChanged = this.onFilterOptionChanged.bind(this);
        this.showSelectedPageDocuments = this.showSelectedPageDocuments.bind(this);
        this.downloadMarkedDocuments = this.downloadMarkedDocuments.bind(this);
        this.renderMenuBar = this.renderMenuBar.bind(this);
    }

    componentDidMount() {
        document.title = 'MCA: News List';
        this.showSelectedPageDocuments(1);
    }


    downloadMarkedDocuments(event) {
        let docType = event.target.value;
        const token = localStorage.getItem('token');
        const csrftoken = getCookie('csrftoken');
        let url = `${DOCS_URL}export/?doctype=${docType}`;
        fetch(url, {
            method: 'post',
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
            }
        })
            .then(checkHttpStatus)
            .then(parseJSON)
            .then((response) => {
                this.showSelectedPageDocuments();
                exportToCSV(response, docType);
            })
            .catch((error) => {
                handleCommonErrors(error)
            })
    }

    showSelectedPageDocuments(pageNumber = 1, filter = this.state.filter) {
        console.log("Selected page number: " + pageNumber);
        const token = localStorage.getItem('token');
        let url = `${DOCS_URL}list/?page=${pageNumber}`;
        if (filter !== null) {
            url = url + `&filter=${filter}`;
        }
        fetch(url, {
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`
            }
        })
            .then(checkHttpStatus)
            .then(parseJSON)
            .then((response) => {
                console.log(`Received: ${response.count} documents`);
                this.setState({
                    news: response.results,
                    totalDocs: response.count,
                    filter: filter,
                    selectedPage: pageNumber
                });
            })
            .catch((error) => {
                console.log(error);
                return Promise.resolve(); // TODO: we need a promise here because of the tests, find a better way
            });
    }

    uploadNewFileClicked() {
        this.setState({showUploadDialog: true});
    }

    closeUploadDialog() {
        this.setState({showUploadDialog: false})
    }

    onFilterOptionChanged(event) {
        let selectedOption = event.target.value;

        if (selectedOption === ALL_DOCS) {
            this.showSelectedPageDocuments(1, null);
        } else {
            this.showSelectedPageDocuments(1, event.target.value);
        }
    }

    /*
    In this method we render the
    */
    renderMenuBar() {
        return (
            <div className="options">
                <div className="bp3-select bp3-large">
                    <select onChange={this.onFilterOptionChanged}>
                        <option value="" disabled>Filter</option>
                        {
                            OPTIONS.map((option, idx) => {
                                return <option
                                    key={idx}
                                    value={option.key}>{option.display}</option>
                            })
                        }
                    </select>
                </div>
                <div className="option">
                    <Button className="upload-button" onClick={this.uploadNewFileClicked} intent={'primary'}
                            large='true'>
                        <Icon icon="cloud-upload"/><span style={{padding: 10}}>Upload New Document</span>
                    </Button>
                    <div className="marked-documents-download-button">
                        <div className={'bp3-select bp3-large'}>
                            <select onChange={this.downloadMarkedDocuments} value="download">
                                <option value={'download'} disabled>Export to CSV</option>
                                <option value={'AGM'}> Annual General Meeting</option>
                                <option value={'KID'}> Dividends</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
        )
    }

    render() {
        return (
            <div style={{display: 'flex', marginTop: 60}}>
                {this.state.showUploadDialog ?
                    <UploadNewFile isOpen={this.state.showUploadDialog}
                                   onClose={this.closeUploadDialog}
                                   documentUploaded={() => this.showSelectedPageDocuments(1)}/> : null}
                <div style={{margin: 'auto', width: "90%"}}>
                    {this.renderMenuBar()}
                    <DocumentsTable
                        news={this.state.news}
                        selectedPage={this.state.selectedPage}
                        showSelectedPageDocuments={this.showSelectedPageDocuments}
                        totalDocs={this.state.totalDocs}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userName: state.auth.userName,
        documents: state.docs.documents,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDocumentsFromServer: () => dispatch(fetchDocumentsFromServer())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeView);
export {HomeView as HomeViewNotConnected};
