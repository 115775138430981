import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { DOCUMENT_URL } from '../../utils/config';
import { checkHttpStatus, parseJSON, getCookie } from '../../utils';
import { DOCS_URL } from '../../utils/config';
import { convertDateToLocaleString } from "../../utils";
import { Icon, Button } from "@blueprintjs/core";
import KIDTable from "./KIDTable";

const NEWS_WEB_URL = `https://newsweb.oslobors.no/message/`;
const AGM = 'AGM';
const KID = 'KID';


export default class DocumentTableRow extends Component {

  constructor(props) {
    super(props);
    this.state = {
      kid: {},
      showKIDRow: false,
      showKIDModal: false,
    }
    // this.showKIDContent = this.showKIDContent.bind(this);
    this.hideKIDContent = this.hideKIDContent.bind(this); //TODO: Make both above functions generic
    this.markDocumentForSending = this.markDocumentForSending.bind(this);
    this.showKIDModal = this.showKIDModal.bind(this);
    this.fetchKID = this.fetchKID.bind(this);
    this.showKID = this.showKID.bind(this);
  }

  showKID(kid) {
    this.setState({
      kid: kid,
      showKIDRow: true,
      showKIDModal: false
    });
  }

  showKIDModal(kid) {
    this.setState({
      kid: kid,
      showKIDModal: true,
      showKIDRow: false
    });
  }

  fetchKID(callback) {
    const token = localStorage.getItem('token');
    let documentId = this.props.doc.id;

    fetch(`${DOCUMENT_URL}${documentId}`, {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      }
    })
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        callback(response.message)
      })
      .catch((error) => {
        if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
          return error.response.json().then((data) => {
            console.log(data);
          });
        } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500)
          console.log("Server error")
      })
  }

  hideKIDContent() {
    this.setState({
      showKIDModal: false,
      showKIDRow: false
    });
  }



  renderDocLink() {
    switch (this.props.doc.doc_type_code) {
      case AGM: return (
        <Link to={`/doc/results/${this.props.doc.id}`} className={this.props.disabled} >
          {this.props.doc.news_title}
        </Link>
      );
      case KID: return <Link onClick={() => this.fetchKID(this.showKID)} to="">{this.props.doc.news_title}</Link>;
      default:
        return (
          <Link to={`/doc/results/${this.props.doc.id}`} className={this.props.disabled} >
            {this.props.doc.news_title}
          </Link>
        );
    }
  }


  markDocumentForSending() {
    const csrftoken = getCookie('csrftoken');
    const token = localStorage.getItem('token');
    fetch(`${DOCS_URL}${this.props.doc.id}`, {
      method: 'post',
      body: JSON.stringify({
      }),
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
        'X-CSRFToken': csrftoken,
        'Content-Type': 'application/json',
      }
    })
      .then(checkHttpStatus)
      .then(parseJSON)
      .then((response) => {
        this.props.onDocumentMarked(this.props.currentPage);
      })
      .catch((error) => {
        console.error(error);
        if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
          return error.response.json().then((data) => {
            console.log("error")
          });
        } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500)
          console.log("Server error" + error);
      });
  }


  render() {
    return (
      <React.Fragment>
        <tr key={this.props.doc.id} className={`document-list-item ${this.props.processingClass}`}>
          <td className='doc-link'>
            {this.renderDocLink()}
          </td>
          <td>{this.props.doc.issuer_name}</td>
          <td>{this.props.doc.doc_type}</td>
          <td>{convertDateToLocaleString(this.props.doc.uploaded_time)}</td>
          <td>
            {
              this.props.doc.message_id !== null ?
                <a href={NEWS_WEB_URL + this.props.doc.message_id} className={'newsweb-link'} target="__blank">
                  <Icon icon="share" />
                </a>
                :
                null
            }
          </td>
        </tr>
        {
          this.state.showKIDRow ?
            <React.Fragment>
              <tr>
                <td colSpan={3}>
                  <KIDTable kid={this.state.kid} />
                </td>
              </tr>
              <tr>
                <Button
                  icon="collapse-all"
                  intent="primary"
                  onClick={this.hideKIDContent}>Close</Button>
                <Button
                  icon="envelope"
                  intent="success"
                  onClick={this.markDocumentForSending}>Mark for sending</Button>
              </tr>
            </React.Fragment>
            :
            null
        }
      </React.Fragment>
    )
  }
}