// https://stackoverflow.com/questions/18848860/javascript-array-to-csv/18849208

function objectToCSVRow(dataObject) {
    let dataArray = [];
    for (let o in dataObject) {
        let innerValue = dataObject[o] === null ? '' : dataObject[o].toString();
        let result = innerValue.replace(/"/g, '""');
        result = '"' + result + '"';
        dataArray.push(result);
    }
    return dataArray.join(';') + '\r\n'; //TODO: move to a config variable
}


export function exportToCSV(arrayOfObjects, name) {

    if (!arrayOfObjects.length) {
        return;
    }

    let csvContent = "data:text/csv;charset=utf-8,";
    let keys = Object.keys(arrayOfObjects[0]);

    csvContent += objectToCSVRow(keys);

    arrayOfObjects.forEach(function (item) {
        csvContent += objectToCSVRow(item);
    });

    let fileName = `mca_${name}.csv`
    openSaveDialog(fileName, csvContent);
}

export function openSaveDialog(fileName, data){
    let encodedUri = encodeURI(data);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
}
