import React from 'react';
import { Route, Switch } from 'react-router';
import { HomeView, LoginView, NotFoundView, ResultsView } from './containers';
import requireAuthentication from './utils/requireAuthentication';
import NewsView from './containers/NewsView';


/**
 * Routes for the application, requireAuthentication is for protected routes
 * Any route that doesn't match will be handled by NotFoundView
 */
export default(
    <Switch>
        <Route path="/login" component={LoginView} /> 
        <Route exact path="/doc/results/:documentId" component={requireAuthentication(ResultsView)} />
        <Route exact path="/news" component={requireAuthentication(NewsView)} /> 
        <Route exact path="/" component={requireAuthentication(HomeView)} />       
        <Route path="*" component={NotFoundView} />
    </Switch>

);
