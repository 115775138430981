import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import authReducer from './auth';
import docReducer from './documents';

export default combineReducers({
    auth: authReducer,
    routing: routerReducer,
    docs: docReducer,
});
