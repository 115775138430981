import{
    VALIDATED,
    PROCESSING,
    UPDATE_FILES_LIST ,
    DOCUMENTS_FETCHED,
    MARK_DOCUMENT_TO_SEND,
} from '../constants';


export default function docReducer(state = {}, action) {
    let documents = state.documents;
    switch (action.type) {
        case DOCUMENTS_FETCHED: 
            let newState = Object.assign({}, state, {documents: action.payload.documents});
            return newState;
        case MARK_DOCUMENT_TO_SEND:
            // TODO: make it better
            let documentsUpdated = []; 
            for(let item of documents){
                if(item.id === action.payload.documentId){
                    item.processing_status = VALIDATED;
                } 
                documentsUpdated.push(item);
            }
            return Object.assign({}, state, {documents: documentsUpdated});
        case UPDATE_FILES_LIST:
            let files = action.payload.files;
            let newDocuments = []; 
            for(let file of files){
                var fulldate = convertDateToHumanreadable(new Date());
                newDocuments.push({
                    id: documents.length+1, 
                    news_title: file.name, 
                    processing_status: PROCESSING, 
                    company_name: "-", 
                    uploaded_time: fulldate, 
                    doc_type: "-"
                });
            }
            newDocuments = newDocuments.concat(documents);
            return Object.assign({}, state, {documents: newDocuments});
        default:
            return state;
    }
}

function convertDateToHumanreadable(date) {
    let readable = new Date(date);
    let m = readable.getMonth(); // returns 6
    let d = readable.getDate(); // returns 15
    let y = readable.getFullYear();
    let months = ["Jan", "Feb", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let mlong = months[m];
    let fulldate = d + " " + mlong + ", " + y;
    return fulldate;
}

