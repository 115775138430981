import React, {Component} from 'react';


class PageNumberItem extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.onItemClicked = this.onItemClicked.bind(this);
    }

    onItemClicked(event) {
        this.props.onPageNumberClicked(this.props.pageNumber);
    }


    render() {
        return (
            <React.Fragment>
                <a className="pagination" style={{fontWeight: this.props.isActive ? 'bold' : 'normal'}}
                   onClick={this.onItemClicked}>{this.props.name}</a>
            </React.Fragment>
        )
    }
}

export default PageNumberItem;