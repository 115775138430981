import React from 'react';
import {connect} from 'react-redux';
import {Button} from "@blueprintjs/core";
import {checkHttpStatus, parseJSON, getCookie, orderEntities} from '../../utils';

import "./index.css";
import ExtractedEntity from './ExtractedEntity';
import {markDocumentForSending} from "../../actions/docs";
import {ENTITY_URL, DOCUMENT_URL, entityDisplayOrder, BASE_URL} from '../../utils/config';
import {showSuccessToast, handleCommonErrors} from "../../utils";


/**
 * View to show results for various document types.
 * It shows different components based on the document type
 */
class ResultsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            numPages: 0,
            document: null,
            pdf_url: '',
            entities: []
        };
        this.entityChanged = this.entityChanged.bind(this);
    }

    onDocumentLoadSuccess = ({numPages}) => {
        this.setState({numPages});
    };

    entityChanged(entity, value) {
        const token = localStorage.getItem('token');
        const csrftoken = getCookie('csrftoken');

        fetch(`${ENTITY_URL}/${entity.pk}`, {
            method: 'post',
            body: JSON.stringify({
                value: value
            }),
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
            }
        })
            .then(checkHttpStatus)
            .then((response) => {
                showSuccessToast("Entity Edited Successfully")
            })
            .catch((error) => {
                handleCommonErrors(error);
            })
    }


    componentDidMount() {
        document.title = "MCA: Extracted Information";
        const token = localStorage.getItem('token');
        let documentId = this.props.match.params.documentId;

        fetch(`${DOCUMENT_URL}${documentId}`, {
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(checkHttpStatus)
            .then(parseJSON)
            .then((response) => {
                document.title = `MCA: ${response['message']['news_title']}`;
                this.setState({
                    entities: response['message']['entities'],
                    pdf_url: response['message']['valid_attachment']
                })
            })
            .catch((error) => {
                handleCommonErrors(error)
            })
    }

    renderEntities() {

        let entities = orderEntities(this.state.entities, entityDisplayOrder); // each element as key value pair

        return entities.map((entity, idx) => {
            if (entity.key === 'meetingAgendaList') {
                return null;
            }
            return (
                <ExtractedEntity
                    key={idx}
                    entity={entity}
                    entityChanged={this.entityChanged}/>
            )
        })
    }

    render() {
        let documentId = this.props.match.params.documentId;
        return (
            <div id="results-container">
                <div id="entity-list">
                    <ul>
                        {this.renderEntities()}
                    </ul>
                </div>
                <div id="pdf-viewer-test">
                    <object data={BASE_URL + this.state.pdf_url} type="application/pdf" width="100%" height="100%">
                        <embed src={BASE_URL + this.state.pdf_url} type="application/pdf"/>
                    </object>
                    <div className="floating-buttons">
                        <Button
                            intent="primary"
                            icon="envelope"
                            className='action-button'
                            style={{width: 200, height: 50}} large
                            onClick={() => this.props.onSendClicked(documentId)}>Mark for sending</Button>
                    </div>
                </div>
            </div>
        );
    }
}


/*
*/
const mapDispatchToProps = (dispatch) => {
    return {
        onSendClicked: (documentId) => dispatch(markDocumentForSending(documentId))
    }
};

export default connect(null, mapDispatchToProps)(ResultsView);
export {ResultsView as ResultsViewNotConnected};
