import {AppToaster} from '../components/Toaster.jsx';


export function checkHttpStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

export function parseJSON(response) {
    return response.json();
}


export function convertDateToDashed(date) {
    // https://stackoverflow.com/a/35869246 without reversing
    return date.toISOString().replace(/T.*/, '').split('-').join('-');
}


export function getCookie(name) {
    if (!document.cookie) {
        return null;
    }
    const token = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));

    if (token.length === 0) {
        return null;
    }
    return decodeURIComponent(token[0].split('=')[1]);
}

export function convertDateToLocaleString(date) {
    return new Date(date).toLocaleString();
}

export function parseDateFromString(string) {
    try {
        let date = new Date(string);
        if (!date.getDate()) {
            return string
        }
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } catch (err) {
        return string
    }
}

export function orderEntities(entities, order) {
    let itemArray = [];
    for (let item of order) {
        for (let entity of entities) {
            if (entity.key === item) {
                itemArray.push(entity)
            }
        }

    }
    return itemArray;
}


export function getProperty(o, prop) {
    if (o[prop] !== undefined) return o[prop];
    else return "";
}


export function convertDashedDatetoDateObject(dashedDate) {
    let splitted = dashedDate.split("-");
    return new Date(splitted[0], splitted[1] - 1, splitted[2]);
}

export function showSuccessToast(message) {
    AppToaster.show({
        message: message,
        intent: 'primary'
    });
}

export function showErrorToast(message) {
    AppToaster.show({
        message: message,
        intent: 'danger'
    });
}

export function handleCommonErrors(error) {
    if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
        return error.response.json().then((data) => {
            showErrorToast(data);
        });
    } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500)
        showErrorToast("Server error, please try again later")
}