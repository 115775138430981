import React, {Component} from 'react'

import {
    ENTITY_KEY_TO_TITLE
} from "../../constants";
import {Card, Icon} from "@blueprintjs/core";


export default class ExtractedEntity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            value: this.props.entity.value
        };
        this.editButtonClicked = this.editButtonClicked.bind(this);
    }

    editButtonClicked() {
        if (this.state.editing) {
            this.props.entityChanged(this.props.entity, this.state.value);
        }
        this.setState({
            editing: !this.state.editing
        })
    }

    render() {
        let isEntityCalculated = this.props.entity.is_calculated;
        return (
            <Card interactive={true}>
                <h5>
                    <a href="#">
                        {ENTITY_KEY_TO_TITLE[this.props.entity.key]}
                    </a>
                </h5>
                {
                    !this.state.editing ?
                        <div>
                            <p>{this.state.value}</p>
                            <div className={'edit-entity-button'}>
                                <Icon
                                    tabIndex={0}
                                    icon={this.state.editing ? 'tick' : 'edit'}
                                    onClick={this.editButtonClicked}/>
                                {isEntityCalculated ?
                                    <Icon
                                        tabIndex={0}
                                        htmlTitle={"This entity was not extracted from the document. It was calculated using other values." +
                                        "\n If you want to change this please edit other related entities as well"}
                                        icon={'calculator'}/> : null}
                            </div>
                        </div>
                        :
                        <div className="bp3-input-group">
                            {
                                this.props.entity.key === '' ?
                                    <input
                                        type="text"
                                        className="bp3-input"
                                        value={this.state.value}
                                        onChange={(event) => {
                                            this.setState({value: event.target.value})
                                        }}/>
                                    :
                                    <textarea
                                        type="text"
                                        className="bp3-input"
                                        rows={8}
                                        value={this.state.value}
                                        onChange={(event) => {
                                            this.setState({value: event.target.value})
                                        }}/>
                            }
                            <button tabIndex={0}
                                    className="save-entity-button bp3-button bp3-minimal bp3-intent-warning bp3-icon-tick"
                                    onClick={this.editButtonClicked}>Save Changes
                            </button>
                        </div>
                }
            </Card>
        )
    }
}