import fetch from 'isomorphic-fetch';
import { push } from 'react-router-redux';

import { SERVER_URL, DOCS_URL } from '../utils/config';
import { checkHttpStatus, parseJSON, getCookie } from '../utils';
import { DOCUMENTS_FETCHED, UPDATE_FILES_LIST } from '../constants';



export function documentsFetched(documents){
    return {
        type: DOCUMENTS_FETCHED,
        payload: {
            documents: documents.results  
        } 
    }; 
} 


export function fetchDocumentsFromServer(){
    const token = localStorage.getItem('token');

    return (dispatch, state) => {
        return fetch(`${SERVER_URL}/api/v1/docs/list/`, {
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`
            }
        })
            .then(checkHttpStatus)
            .then(parseJSON)
            .then((response) => {
                dispatch(documentsFetched(response));
            })
            .catch((error) => {
                if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
                    return error.response.json().then((data) => {
                        dispatch(push('/login'));
                    });
                } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
                    // Server side error
                    console.error('A server error occurred while sending your data!');
                } 
                dispatch(push('/login'));
                return Promise.resolve(); // TODO: we need a promise here because of the tests, find a better way
            });
    };

} 

export function markDocumentForSending(documentId){
    return (dispatch, state) => {
        const csrftoken = getCookie('csrftoken');
        const token = localStorage.getItem('token');
        fetch(`${DOCS_URL}${documentId}`, {
            method: 'post',
            body: JSON.stringify({
            }),
            credentials: 'same-origin',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
            }
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then((response) => {
            dispatch(push('/'))
        })
        .catch((error) => {
            console.error(error);
            if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
                return error.response.json().then((data) => {
                    dispatch(push('/login'));
                });
            } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500)
                console.log("Server error" + error)
            })
      }

} 


export function updateUploadedFiles(files){
    return {
        type: UPDATE_FILES_LIST,
        payload: {
            files
        }
    }
}