import React from 'react';

export default class NotFoundView extends React.Component {
    render() {
        return (
            <div>
                <h1>Page Not Found</h1>
            </div>
        );
    }
}
