import React, { Component } from "react";
import {
  checkHttpStatus,
  convertDashedDatetoDateObject,
  getCookie,
  parseDateFromString,
  parseJSON,
  handleCommonErrors
} from "../../utils";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import { Button, InputGroup } from "@blueprintjs/core";
import { DOCS_URL } from "../../utils/config";
import { AppToaster } from "../../components/Toaster.jsx";
import { openSaveDialog } from "../../utils/csvUtils";

export default class KIDTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saveEnabled: true,
      changed: []
    };
    this.changeDividendEntities = this.changeDividendEntities.bind(this);
    this.downloadSwiftMessage = this.downloadSwiftMessage.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.setState({
      [event["target"]["name"]]: event.target.value
    });
  }

  downloadSwiftMessage() {
    console.log(this.props);
    const token = localStorage.getItem("token");
    const csrftoken = getCookie("csrftoken");
    let url = `${DOCS_URL}swift/${this.props.kid.news_item}`;
    fetch(url, {
      method: "post",
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
        "X-CSRFToken": csrftoken,
        "Content-Type": "application/json"
      }
    })
      .then(checkHttpStatus)
      .then(parseJSON)
      .then(response => {
        console.log(response);
        let data = "data:text/text;charset=utf-8,";
        data = data + response.message;
        console.log(data);
        openSaveDialog("swift_message.txt", data);
      })
      .catch(error => {
        handleCommonErrors(error);
      });
  }

  changeDividendEntities() {
    this.setState({ saveEnabled: false });
    const csrftoken = getCookie("csrftoken");
    const token = localStorage.getItem("token");

    fetch(`${DOCS_URL}kid/${this.props.kid.id}`, {
      method: "post",
      body: JSON.stringify({
        dividend_amount: this.state.dividend_amount,
        currency_symbol: this.state.currency_symbol,
        last_day_including_right: convertDashedDatetoDateObject(
          this.state.last_day_including_right
        ),
        record_date: convertDashedDatetoDateObject(this.state.record_date),
        payment_date: convertDashedDatetoDateObject(this.state.payment_date),
        date_of_approval: this.state.date_of_approval,
        ex_date: convertDashedDatetoDateObject(this.state.ex_date)
      }),
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        Authorization: `Token ${token}`,
        "X-CSRFToken": csrftoken,
        "Content-Type": "application/json"
      }
    })
      .then(checkHttpStatus)
      .then(response => {
        AppToaster.show({
          message: "Entity Edited Successfully",
          intent: "primary"
        });
        this.setState({
          saveEnabled: true
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          saveEnabled: true
        });
        if (
          error &&
          typeof error.response !== "undefined" &&
          error.response.status === 401
        ) {
          return error.response.json().then(data => {
            AppToaster.show({
              message: data,
              intent: "danger"
            });
            console.error("Token expired, login again");
          });
        } else if (
          error &&
          typeof error.response !== "undefined" &&
          error.response.status >= 500
        )
          console.log("Server error" + error);
        AppToaster.show({
          message: "Server Error, please try again later",
          intent: "danger"
        });
      });
  }

  componentDidMount() {
    let state = this.state;
    for (let item in this.props.kid) {
      state[item] = parseDateFromString(this.props.kid[item]);
    }
    this.setState({ ...state });
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{ whiteSpace: "pre-line", textAlign: "left", padding: "5px" }}
        >
          {this.props.kid.body_text}
        </div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th title="Field #2">Dividend Amount</th>
              <th title="Field #3">Currency Symbol</th>
              <th title="Field #5">Last Day Including Right</th>
              <th title="Field #6">Ex Date</th>
              <th title="Field #7">Record Date</th>
              <th title="Field #8">Payment Date</th>
              <th title="Field #9">Date of Approval</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <InputGroup
                  value={this.state.dividend_amount}
                  name={"dividend_amount"}
                  onChange={this.onChange}
                />
              </td>
              <td>
                <InputGroup
                  value={this.state.currency_symbol}
                  name={"currency_symbol"}
                  onChange={this.onChange}
                />
              </td>
              <td>
                <InputGroup
                  value={this.state.last_day_including_right}
                  name={"last_day_including_right"}
                  onChange={this.onChange}
                />
              </td>
              <td>
                <InputGroup
                  value={this.state.ex_date}
                  name={"ex_date"}
                  onChange={this.onChange}
                />
              </td>
              <td>
                <InputGroup
                  value={this.state.record_date}
                  name={"record_date"}
                  onChange={this.onChange}
                />
              </td>
              <td>
                <InputGroup
                  value={this.state.payment_date}
                  name={"payment_date"}
                  onChange={this.onChange}
                />
              </td>
              <td>
                <InputGroup
                  value={this.state.date_of_approval}
                  name={"date_of_approval"}
                  onChange={this.onChange}
                />
              </td>
              <td>
                <Button
                  intent="success"
                  fill
                  onClick={this.changeDividendEntities}
                >
                  Save
                </Button>
                <Button
                  intent="success"
                  fill
                  onClick={this.downloadSwiftMessage}
                >
                  Download SWIFT
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}
