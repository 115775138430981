import React, {Component} from 'react';
import PropTypes from 'prop-types';
import PageNumberItem from "./PageNumberItem";


const SHOW_PAGE_SIZE = 3;

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];
    while (i <= to) {
        range.push(i);
        i += step;
    }
    return range;
};


class Pagination extends Component {

    static propTypes = {
        numDocs: PropTypes.number,
        selectedPage: PropTypes.number,
    };


    constructor(props) {
        super(props);
        this.state = {};
        this.renderPageNumbers = this.renderPageNumbers.bind(this);
        this.renderWordedPageNumber = this.renderWordedPageNumber.bind(this);
    }

    onPageNumberClicked(pageNumber) {
        this.props.onPageNumberClicked(pageNumber);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.selectedPage !== this.props.selectedPage || nextProps.totalPages !== this.props.totalPages;
    }

    renderPageNumbers(start, end) {
        return range(start, end, 1).map((item, idx) => <PageNumberItem
            key={item}
            onPageNumberClicked={this.props.onPageNumberClicked}
            name={item}
            pageNumber={item}
            isActive={item === this.props.selectedPage}
        />)
    }

    renderWordedPageNumber(name, pageNumber) {
        return <PageNumberItem
            onPageNumberClicked={this.props.onPageNumberClicked}
            name={name}
            key={pageNumber}
            pageNumber={pageNumber}
        />
    }

    renderPagination() {
        let numDocs = this.props.totalPages;
        let numPages = Math.ceil(numDocs / 10);
        let totalPages = numPages;

        let pageDisplayArray = [];

        let totalPagesLessThanWindowSize = totalPages <= SHOW_PAGE_SIZE;
        let nearEnd = this.props.selectedPage > (totalPages - SHOW_PAGE_SIZE)
            && this.props.selectedPage <= (totalPages);
        let nearStart = this.props.selectedPage < SHOW_PAGE_SIZE && totalPages > SHOW_PAGE_SIZE;
        let inBetween = !nearStart && !nearEnd;

        if (totalPagesLessThanWindowSize) {
            pageDisplayArray.push(this.renderPageNumbers(1, totalPages));
            return pageDisplayArray;
        }
        if (nearStart) {
            if (totalPagesLessThanWindowSize) {
                pageDisplayArray.push(this.renderPageNumbers(1, totalPages));

            } else {
                pageDisplayArray.push(this.renderPageNumbers(1, SHOW_PAGE_SIZE));
                pageDisplayArray.push(this.renderWordedPageNumber('Last', numPages));
            }
        } else if (inBetween) {
            pageDisplayArray.push(this.renderWordedPageNumber('First', 1));
            pageDisplayArray.push(this.renderPageNumbers(this.props.selectedPage - 1, this.props.selectedPage + 2));
            pageDisplayArray.push(this.renderWordedPageNumber('Last', numPages))
        } else if (nearEnd) {
            pageDisplayArray.push(this.renderWordedPageNumber('First', 1));
            pageDisplayArray.push(this.renderPageNumbers(this.props.selectedPage - 1, totalPages));
        }
        return pageDisplayArray;
    }


    render() {

        let pagination = this.renderPagination();
        return (
            <div className="pagination">
                {pagination}
            </div>
        )
    }
}

export default Pagination;
