import React from 'react';

import { NEWS_URL, MESSAGE_VIEW_URL } from '../../utils/config';
import { checkHttpStatus, parseJSON, convertDateToDashed } from '../../utils';
import { DateInput } from '@blueprintjs/datetime';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import { NonIdealState } from '@blueprintjs/core';
import {AppToaster} from '../../components/Toaster.jsx';


import './index.css';



export default class NewsView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            news: [],
            selectedDate: new Date()
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.populateNews = this.populateNews.bind(this);
        this.renderNewsList = this.renderNewsList.bind(this);
    }


    renderNewsList() {
        return this.state.news.map((news, idx) => {
            return (
                <tr key={news.news_id} style={news.is_relevant ? { backgroundColor: 'aquamarine' } : undefined}  >
                    <td><a href={MESSAGE_VIEW_URL + news.message_id} target="__blank"> {news.news_title} </a></td>
                    <td>{news.issuer_name}</td>
                    <td>{new Date(news.published_time).toLocaleString()}</td>
                    <td>{news.doc_type}</td>
                </tr>
            )
        })
    }

    populateNews(date) {
        const token = localStorage.getItem('token');

        fetch(`${NEWS_URL}?date=${date}`, {
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`
            }
        })
            .then(checkHttpStatus)
            .then(parseJSON)
            .then((response) => {
                this.setState({ news: response })
            })
            .catch((error) => {
                if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
                    return error.response.json().then((data) => {
                        console.error(data);
                        console.error(error);
                        AppToaster.show({
                            message: "Please try again later",
                            intent: 'danger'
                        });
                    });
                } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
                    // Server side error
                    console.error(error);
                    AppToaster.show({
                        message: "Server error, please try again later",
                        intent: 'danger'
                    });
                }
                return Promise.resolve();
            });
    }


    componentDidMount() {
        document.title = 'MCA: All News Items';
        let today = new Date();
        today = convertDateToDashed(today);
        this.populateNews(today);
    }

    handleDateChange(date) {
        if (date && this.state.date !== date) {
            this.setState({ selectedDate: date });
            this.populateNews(convertDateToDashed(date));
        }
    }

    render() {
        return (
            <div id="news-container">
                <div style={{ margin: 'auto', width: '90%' }} >
                    <DateInput
                        formatDate={date => date.toLocaleDateString()}
                        onChange={this.handleDateChange}
                        parseDate={str => convertDateToDashed(new Date(str))}
                        placeholder={convertDateToDashed(new Date())}
                        maxDate={new Date()}
                        // firstDayOfWeek={1}
                        dayPickerProps={{ firstDayOfWeek: 1 }}
                        value={this.state.selectedDate}
                    />
                    {
                        this.state.news.length === 0 ?
                            <NonIdealState icon={'search'}
                                style={{ marginTop: '25%' }}
                                title="No news items found for this date in database"
                                description={this.state.description ? this.state.description : undefined} />
                            :
                            <table class="table" id='doc-table' style={{ textAlign: 'left', width: '100%' }} >
                                <thead>
                                    <tr>
                                        <th scope="col">Document Title</th>
                                        <th scope="col">Issuer Name</th>
                                        <th scope="col">Published On</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderNewsList()}
                                </tbody>
                            </table>
                    }
                </div>
            </div>
        );
    }
}
