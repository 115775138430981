let base_url = process.env.REACT_APP_API_URL;
let server_url = base_url  + "/mca";

export const BASE_URL = base_url;
export const SERVER_URL = server_url;
export const API_URL = SERVER_URL + "/api/v1/";
export const LOGIN_URL = API_URL + "accounts/login/";

export const DOCS_URL = API_URL + "docs/";
export const NEWS_URL = DOCS_URL + "news/";
export const ENTITY_URL = DOCS_URL + "entity";
export const DOCUMENT_URL = DOCS_URL;

export const REFRESH_SECONDS = 20;
export const REFRESH_INTERVAL = REFRESH_SECONDS * 1000; // Don't change it directly


export const entityDisplayOrder = [
    'documentType',
    'companyName',
    'isin',
    'meetingDate',
    'meetingAddress',
    'companyDeadline',
    'recordDate',
    'ourDeadline',
    'meetingAgenda',
];

export const MESSAGE_VIEW_URL = "https://newsweb.oslobors.no/message/";


// config should use named export as there can be different exports,
// just need to export default also because of eslint rules
export { SERVER_URL as default };
