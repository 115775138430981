import ColorLegend from "./ColorLegend";
import Pagination from "./Pagination";
import React from "react";
import DocumentTableRow from "./DocumentTableRow";
import {KEY_TO_CLASS} from "../../constants";

/*
This component renders the table of documents.
By rendering each incoming document as a DocumentTableRow component
*/
export default class DocumentsTable extends React.Component {

    renderDocuments() {
        let filteredDocuments = this.props.news;

        return filteredDocuments.map((doc, idx) => {
            let disabled = doc.processing_status === 'P' ? 'disabled-link' : null;
            let processingClass = this.getProcessingClass(doc.processing_status);
            return (
                <DocumentTableRow key={idx}
                                  doc={doc}
                                  disabled={disabled}
                                  processingClass={processingClass}
                                  currentPage={this.props.selectedPage}
                                  onDocumentMarked={this.props.showSelectedPageDocuments}
                />
            )
        })
    }

    getProcessingClass(status) {
        return KEY_TO_CLASS[status];
    }

    render() {
        return (
            <div>
                <ColorLegend/>
                <table className="table" id='doc-table' >
                    <thead >
                    <tr>
                        <th scope="col">Document Title</th>
                        <th scope="col">Company Name</th>
                        <th scope="col">Document Type</th>
                        <th scope="col">Added On</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderDocuments()}
                    </tbody>
                </table>
                <div className="pagination-block">
                    <Pagination
                        selectedPage={this.props.selectedPage}
                        totalPages={this.props.totalDocs}
                        onPageNumberClicked={this.props.showSelectedPageDocuments}/>
                </div>
            </div>
        );
    }
}

