import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

import { authLogoutAndRedirect } from './actions/auth';
import './styles/margins.css';
import './styles/reset.css';
import './styles/index.css';

import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/table/lib/css/table.css';
import '@blueprintjs/core/lib/css/blueprint.css';


import {
    Classes,
    NavbarGroup,
    NavbarDivider,
    NavbarHeading,   
    Navbar,
    Alignment,
    Button
} from "@blueprintjs/core";

const SUPPORT_MAIL_ADDRESS = "kamal.singh@dnb.no";


class App extends React.Component {
    static propTypes = {
        isAuthenticated: PropTypes.bool.isRequired,
        children: PropTypes.shape().isRequired,
        dispatch: PropTypes.func.isRequired,
        location: PropTypes.shape({
            pathname: PropTypes.string
        })
    };

    static defaultProps = {
        location: undefined
    };

    logout = () => {
        this.props.dispatch(authLogoutAndRedirect());
    };

    goToIndex = () => {
        this.props.dispatch(push('/'));
    };

    goToLogin = () => {
        this.props.dispatch(push('/login'));
    };

    goToNews = () => {
        this.props.dispatch(push('/news'));
    };

    goToMetrics = () => {
        this.props.dispatch(push('/metrics'));
    };

    render() {
        return (
            <div className="app">
                <Navbar className="bp3-dark" style={{backgroundColor: "#23a37a"}}>
                    <NavbarGroup align={Alignment.LEFT}>
                        <NavbarHeading>
                            MCA - v2
                        </NavbarHeading>
                        {
                            this.props.isAuthenticated?
                            <React.Fragment>
                                <NavbarDivider />
                                <Button className={Classes.MINIMAL} onClick={this.goToIndex} icon="home" text="Home" />
                                <Button className={Classes.MINIMAL} onClick={this.goToNews} icon="list" text="News" />
                            </React.Fragment>
                            :
                            null
                        } 
                    </NavbarGroup>
                   {
                       this.props.isAuthenticated?
                        <NavbarGroup align={Alignment.RIGHT}>
                            <NavbarDivider />
                            <Button className={Classes.MINIMAL} icon="help" text="Help" onClick={() => {
                                window.location.href = `mailto:${SUPPORT_MAIL_ADDRESS}`
                            }} />
                            <NavbarDivider />
                            <Button className={Classes.MINIMAL} onClick={this.logout} icon="log-out" text="Logout" />
                        </NavbarGroup>
                        :
                        null
                    }  
                </Navbar>
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
        location: state.routing.location
    };
};

export default connect(mapStateToProps)(App);
export { App as AppNotConnected };
