import React, { Component } from 'react'

const COLORS = [
  {tag: 'Processing', color: '#ff5400'},
  {tag: 'Pending', color: '#ffff7a'},
  {tag: 'Validated', color: '#28b482'},
  {tag: 'Sent', color: '#f5f5f5'},
];

/*
This component is the Labeling of colors corresponding to how far into the process the document have reached 
*/
export default class ColorLegend extends Component {

  render() {
    let el = (
      <div style={{display: 'flex', flexDirection: 'row', width: '400px', border: '2px solid #f5f5f5',
      borderRadius: '5px',}} >
           {COLORS.map((item, idx) => {
               return (
                  <div key={idx} style={{
                    flex: 1,  
                    padding: '5px',
                    backgroundColor: item.color,
                    textAlign: 'center',
                    fontStyle: 'bold',
                    fontSize: '16px'
                }}>
                  {item.tag}
                </div>
               );
           })}
           </div> 
    );
    return el;
  }
}
