import React, { Component } from 'react'
import { Dialog, Classes, Button, Icon } from '@blueprintjs/core';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import classNames from 'classnames'
import SelectedFilesList from './SelectedFilesList';
import { SERVER_URL } from '../../utils/config';
import { checkHttpStatus, parseJSON, getCookie } from '../../utils';


class UploadNewFile extends Component {

  constructor(props){
    super(props);
    this.state = {
      uploadButtonActive: false,
      selectedFiles: []
    };
    this.uploadSelectedFiles = this.uploadSelectedFiles.bind(this);
    this.clearAllSelectedFiles = this.clearAllSelectedFiles.bind(this);
    this.removeFileFromList = this.removeFileFromList.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
  }

  uploadSelectedFiles() {
    // TODO: Upload logic here
    this.setState({uploadButtonActive: false});
    this.uploadFiles(this.state.selectedFiles);
    this.props.onClose()
  }

  removeFileFromList(file){
    let selectedFiles = this.state.selectedFiles.filter((item) => {
      return file.name !== item.name;
    })
    this.setState({
      selectedFiles: selectedFiles
    })
  }


  onDrop = (acceptedFiles, rejectedFiles) => {
    if(acceptedFiles.length === 0 && rejectedFiles.length > 0){
      console.log("Please upload PDF documents only");
      alert("Please upload PDF documents only")
    }else if (acceptedFiles.length > 0){
      this.setState({selectedFiles: acceptedFiles, uploadButtonActive: true});
    }
  }

  clearAllSelectedFiles(){
    this.setState({selectedFiles: [], uploadButtonActive: false})
  }

  uploadFiles(files){
      const token = localStorage.getItem('token');
      let formData = new FormData();
      const csrftoken = getCookie('csrftoken')

      formData.append('file', files[0]);


      fetch(`${SERVER_URL}/api/v1/docs/upload/`, {
            method: 'post',
            body: formData ,
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                Authorization: `Token ${token}`,
                'X-CSRFToken': csrftoken,
            }
        })
        .then(checkHttpStatus)
        .then(parseJSON)
        .then((response) => {
            this.props.documentUploaded();
        })
        .catch((error) => {
            console.log(error);
            if (error && typeof error.response !== 'undefined' && error.response.status === 401) {
                return error.response.json().then((data) => {
                    console.log(data);
                });
            } else if (error && typeof error.response !== 'undefined' && error.response.status >= 500) {
                console.log("Server error")
            } 
        })
      }

  render() {
    return (
      <Dialog 
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        canEscapeKeyClose={true}
        canOutsideClickClose={false}
        style={{ transition: 'fade', width: '50vw' }}
        icon={'upload'}
        title="Upload New Document">
        <div className={Classes.DIALOG_BODY}>
            {this.state.selectedFiles.length > 0 ? 
              <SelectedFilesList selectedFiles={this.state.selectedFiles}
                removeSelectedFile={this.removeFileFromList}
              />
              :
              <Dropzone onDrop={this.onDrop} accept='pdf, application/pdf' multiple={false}>
                {({getRootProps, getInputProps, isDragActive}) => {
                  return (
                  <div>
                      <div
                        style={{maxHeight: '500px', overflowY: 'scroll'}}
                        {...getRootProps()}
                        className={classNames('dropzone', {'dropzone--isActive': isDragActive})}
                      >
                        <input {...getInputProps()} />
                        {
                          isDragActive ?
                            <p>Drop files here...</p> :
                            <div>
                              <p style={{textAlign: "center", lineHeight: '250px'}}>
                                <Icon icon={'cloud-upload'} iconSize='50px' />  
                                <p>Drag file or Click to upload</p>
                              </p>
                            </div>
                        }
                      </div>
                  </div>
                  )
                }}
              </Dropzone>
            }
            <Button 
              fill={true} large 
              intent='primary' 
              disabled={!this.state.uploadButtonActive}
              onClick={this.uploadSelectedFiles}>Upload and Process</Button>
            <br/>
            {
              this.state.selectedFiles.length > 0 ?
              <Button fill={true} large
                intent='warning' onClick={this.clearAllSelectedFiles}>Clear All</Button>
              :
              null
            }
        </div>
      </Dialog>
    )
  }
}


const mapStateToProps = (state) => {
  return {
      userName: state.auth.userName,
      documents: state.docs.documents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  } 
} 

export default connect(mapStateToProps, mapDispatchToProps)(UploadNewFile);
